<template>
	<div class="header-layout">
		<div class="logo-layout">
			<img src="@/assets/logo.png" width="40" height="40"/>
			<span>码趣阁</span>
		</div>
		<Menu />
		<div class="login-register">
			<div v-if="!userName">
				<span class="login-layout" @click="login()">登录/注册</span>
				<!-- <span class="register-layout"></span> -->
			</div>
			<div v-else class="my-course-layout">
				<!-- <span>{{userName}}</span> -->
				<span class="my-course" @click="roUserCenter()">个人中心</span>
			</div>
		</div>
	</div>
</template>


<script>
	import Menu from '@/components/Menu.vue'
	export default {
		name: "Header",
		components: {
			Menu
		},
		data() {
			return {
				userName: ""
			};
		},
		created() {
			this.userName = localStorage.getItem("userName")
		},
		methods: {
			goTarget(href) {
				window.open(href, "_blank");
			},
			login() {
				this.$router.push({
					path: "/login"
				})
			},
			roUserCenter(){
				this.$router.push({
					path: "/userCenter"
				})
			}
		}
	};
</script>


<style scoped lang="less">
	@headHeight: 60px;

	.header-layout {
		width: 1300px;
		height: @headHeight;
		margin: 0px auto;
		border-bottom: 1px solid #ccc;
	}

	.logo-layout {
		float: left;
		width: 200px;
		height: @headHeight;
		line-height: @headHeight;
		span{
			font-size: 20px;
			margin-left: 16px;
			letter-spacing: 2px;
		}
	}

	.login-register {
		float: right;
		height: 30px;
		line-height: 30px;
		margin-top: 15px;

	}

	.login-layout,
	.register-layout,
	.my-course-layout{
		display: inline-block;
		width: 100px;
		height: 30px;
		font-size: 14px;
		line-height: 32px;
		text-align: center;
		letter-spacing: 3px;
		border-radius: 20px;
		cursor: pointer;
		// font-weight: bold;
	}

	.login-layout {
		// background-color: #ACE5FF;
	}


	.register-layout {
		margin-left: 10px;
		background-color: #FFDfC8;
	}
	.my-course-layout{
	}
	.my-course{
		cursor: pointer;
		margin-left: 30px;
		// text-decoration:underline;
	}
</style>