<template>
	<div>
		<Header />
		<div class="course-layout">
			<div v-if="courseList.length>0" v-for="item in courseList">
				<p class="type-layout">{{item.typeName}}</p>
				<ul class="course-item-layout">
					<li v-for="course in item.list" @click="toCourseDetail(course.id)">
						<img v-if="course.isVip==1" class="vip_icon" src="@/assets/course/course_vip.png" />
						<img v-if="course.isVip==0" class="vip_icon" src="@/assets/course/free.png" />
						<img :src="domain+course.coverImage" width="298" height="180" />

						<p>
							<span class="course-name-layout">{{course.title}}</span>
							<span></span>
						</p>
					</li>
				</ul>

			</div>
			<img v-else class="not-data" src="@/assets/course/not_data.png" width="200" height="200" />
		</div>
		<!-- <Footer/> -->
	</div>
</template>

<script>
	import {
		listCourse
	} from "@/api/course"
	import {
		getDomain
	} from "@/utils/config"

	import Header from '@/components/Header.vue'
	import Footer from '@/components/Footer.vue'

	export default {
		name: 'index',
		components: {
			Header,
			Footer
		},
		data() {
			return {
				domain: getDomain(),
				courseList: []
			}
		},
		created() {
			listCourse(this.$route.query.id).then(res => {
				// console.log(res.data.data)
				this.courseList = res.data.data;
			})
		},
		methods: {
			toCourseDetail(id) {
				const {
					href
				} = this.$router.resolve({
					path: '/courseDetail',
					query: {
						id: id
					}
				})
				window.open(href, "_blank")

			}
		}
	}
</script>

<style lang="less" scoped>
	.course-layout {
		width: 1346px;
		height: 300px;
		margin: 0px auto;
		margin-top: 2px;

		// background-color: #eee;
		.type-layout {
			font-size: 22px;
			font-weight: bolder;
			margin-left: 23px;
			margin-top: 10px;
			margin-bottom: 10px;
		}
	}

	.course-item-layout {
		display: inline-block;
		cursor: pointer;

		li {
			position: relative;
			border: 1px solid #eee;
			border-radius: 3px;
			float: left;
			width: 300px;
			// height: 100px;
			margin-right: 10px;
			margin-left: 23px;
			margin-bottom: 30px;
		}

		.vip_icon {
			position: absolute;
			width: 50px;
			height: 50px;
		}
	}

	.course-name-layout {
		display: inline-block;
		width: 300px;
		height: 25px;
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 1px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
		margin-top: 8px;
	}

	.not-data {
		margin-top: 15%;
		margin-left: 45%;
	}
</style>