import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Index from "@/views/Index.vue"
import Course from "@/views/Course.vue"

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/course',
    name: 'course',
    component: () => import("@/views/Course.vue")
  },
  {
    path: '/courseDetail',
    name: 'CourseDetail',
    component: () => import("@/views/CourseDetail.vue")
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import("@/views/Login.vue")
  },
  {
    path: '/userCenter',
    name: 'userCenter',
    component: () => import("@/views/UserCenter.vue")
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes
})

export default router
