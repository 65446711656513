<template>
<!-- 	<nav>
		<router-link to="/">Home</router-link> |
		<router-link to="/about">About</router-link>
	</nav> -->
	<router-view />
</template>

<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	.common-layout{
		width: 1300px;
		min-height: 0px;
		margin: 0px auto;
	}

	html,
	body,
	div,
	ul,
	li,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	dl,
	dt,
	dd,
	ol,
	form,
	input,
	textarea,
	th,
	td,
	select {
		margin: 0;
		padding: 0;
	}

	* {
		box-sizing: border-box;
	}

	html,
	body {
		min-height: 100%;
	}

	body {
		font-family: "Microsoft YaHei";
		font-size: 14px;
		color: #333;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: normal;
	}

	ul,
	ol {
		list-style: none;
	}

	img {
		border: none;
		vertical-align: middle;
	}

	a {
		color: #333;
		text-decoration: none;
		
		
	}

	table {
		border-collapse: collapse;
		table-layout: fixed;
	}

	input,
	textarea {
		outline: none;
		border: none;
	}

	textarea {
		resize: none;
		overflow: auto;
	}

	.clearfix {
		zoom: 1;
	}

	.clearfix:after {
		content: ".";
		width: 0;
		height: 0;
		visibility: hidden;
		display: block;
		clear: both;
		overflow: hidden;
	}

	.fl {
		float: left
	}

	.fr {
		float: right
	}

	.tl {
		text-align: left;
	}

	.tc {
		text-align: center
	}

	.tr {
		text-align: right;
	}

	.ellipse {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.inline {
		display: inline-block;
		*display: inline;
		*zoom: 1;
	}


	
	/deep/.ivu-menu-vertical.ivu-menu-light {
	    content: '';
	    display: block;
	    width: 10px; 
	    height: 100%;
	    background: #dcdee2;
	    position: absolute;
	    top: 0;
	    bottom: 0;
	    right: 0;
	    z-index: 1;
	}
</style>