<template>
	<div>
		<Header />

		<div class="purpose-layout">
			<p class="animate__animated animate__zoomIn animate__slower">{{purpose.content}}</p>
			<p class="animate__animated animate__zoomIn animate__slower animate__delay-1s ">{{purpose.remark}}</p>
			<!-- <p>{{purpose.content}}</p>
			<p>{{purpose.remark}}</p> -->
			<!-- <p>生命不止，奋斗不息，乾坤未定，你我皆是黑马</p> -->
		</div>


		<div class="layout animate__animated animate__zoomIn animate__slower">
			<!-- <div class="layout"> -->
			<div class="skills-layout">
				<!-- <img class="icon" src="@/assets/index/skills.png" /> -->
				<p v-if="skills">{{skills.title}}</p>
				<p v-if="skills">{{skills.content}}</p>
			</div>

			<div class="study-layout">
				<!-- <img class="icon" src="@/assets/index/study.png" /> -->
				<p v-if="study">{{study.title}}</p>
				<p v-if="study">{{study.content}}</p>
			</div>

			<div class="questions-layout">
				<!-- <img class="icon" src="@/assets/index/questions.png" /> -->
				<p v-if="questions">{{questions.title}}</p>
				<p v-if="questions">{{questions.content}}</p>
			</div>

			<!-- <div class="searh-layout">
				如何搜索
			</div> -->
		</div>

		<div class="sort-layout">

			<div class="sql-layout">
				<SQLSort />
			</div>
			<div class="language-layout">
				<LanguageSort />
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
	import animate from "animate.css";

	import Header from '@/components/Header.vue'
	import Footer from '@/components/Footer.vue'
	import SQLSort from '@/components/echarts/SQLSort'
	import LanguageSort from '@/components/echarts/LanguageSort'

	import {
		getDictData
	} from '@/api/dictData'

	export default {
		name: 'index',
		components: {
			Header,
			Footer,
			SQLSort,
			LanguageSort
		},
		data() {
			return {
				purpose: [],
				skills:null,
				study:null,
				questions:null,
			};
		},
		created() {
			this.getPurpose();
		},
		methods: {
			getPurpose() {
				getDictData("index_purpose").then(res => {
					this.purpose = res.data.data[0];
				})
				getDictData("daydayup").then(res => {
					this.skills = res.data.data[0];
					this.study = res.data.data[1];
					this.questions = res.data.data[2];
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.layout,
	.purpose-layout,
	.sort-layout,
	.rsgd {
		width: 1300px;
		margin: 0px auto;
	}

	.purpose-layout {
		height: 240px;
		text-align: center;
		margin-top: 36px;
		// border-bottom: 1px solid red;

		p:first-child {
			color: black;
			font-size: 60px;
			// font-weight: bolder;
			letter-spacing: 6px;
			margin-top: 90px;
			margin-bottom: 20px;
		}

		p:last-child {
			// color: black;
			font-size: 20px;
			letter-spacing: 3px;
		}
	}

	.layout {
		height: 400px;
	}

	.skills-layout,
	.study-layout,
	.questions-layout,
	.searh-layout {
		float: left;
		width: 400px;
		height: 300px;
		color: #333;
		text-align: center;
		background-color: #ccc;

		p:first-child {
			font-size: 36px;
			font-weight: bolder;
			margin-top: 28px;
		}

		p:last-child {
			text-align: left;
			font-size: 20px;
			// margin-top: 28px;
			padding: 20px;
		}
	}

	.icon {
		width: 100px;
		height: 100px;
		text-align: center;
	}

	.skills-layout {
		margin-top: 36px;
		// background-color: #FFDFC8;
		background-color: #ffdfc8;
	}

	.study-layout {
		// background-color: #CFF3D7;
		background-color: #cff3b7;
		margin: 36px 50px 0 50px;
	}

	.questions-layout {
		// background-color: #FFD8DA;
		background-color: #AAE5FF;
		margin: 36px 0px 0 0px;
	}

	.searh-layout {}

	.sort-layout {
		// float: left;
		height: 400px;
		// border: 1px solid red;

		.sql-layout {
			float: left;
			width: 650px;
			height: 400px;
			// background-color: #ccc;
		}

		.language-layout {
			float: left;
			width: 650px;
			height: 400px;
			// background-color: greenyellow;
		}
	}
</style>