<template>
	<div>
		<h3 class="language-title">{{year}}年前十编程语言排行</h3>
		<p class="subtitle">来源：TIOBE</p>
		<Table :columns="columns" :data="data" height="310"></Table>
	</div>
</template>
<script>
	import {
		getDictData
	} from '@/api/dictData'
	export default {
		data() {
			return {
				year: new Date().getFullYear(),
				columns: [{
						title: 'Sep',
						key: 'sort'
					},
					{
						title: 'Programming Language',
						key: 'title'
					},
					{
						title: 'Ratings',
						key: 'content'
					}
				],
				data: [{
						name: 'John Brown',
						age: 18,
						address: 'New York No. 1 Lake Park',
						date: '2016-10-03'
					},
					{
						name: 'Jim Green',
						age: 24,
						address: 'London No. 1 Lake Park',
						date: '2016-10-01'
					},
					{
						name: 'Joe Black',
						age: 30,
						address: 'Sydney No. 1 Lake Park',
						date: '2016-10-02'
					},
					{
						name: 'Jon Snow',
						age: 26,
						address: 'Ottawa No. 2 Lake Park',
						date: '2016-10-04'
					}, {
						name: 'John Brown',
						age: 18,
						address: 'New York No. 1 Lake Park',
						date: '2016-10-03'
					},
					{
						name: 'Jim Green',
						age: 24,
						address: 'London No. 1 Lake Park',
						date: '2016-10-01'
					},
					{
						name: 'Joe Black',
						age: 30,
						address: 'Sydney No. 1 Lake Park',
						date: '2016-10-02'
					},
					{
						name: 'Jon Snow',
						age: 26,
						address: 'Ottawa No. 2 Lake Park',
						date: '2016-10-04'
					}, {
						name: 'John Brown',
						age: 18,
						address: 'New York No. 1 Lake Park',
						date: '2016-10-03'
					},
					{
						name: 'Jim Green',
						age: 24,
						address: 'London No. 1 Lake Park',
						date: '2016-10-01'
					},
					{
						name: 'Joe Black',
						age: 30,
						address: 'Sydney No. 1 Lake Park',
						date: '2016-10-02'
					},
					{
						name: 'Jon Snow',
						age: 26,
						address: 'Ottawa No. 2 Lake Park',
						date: '2016-10-04'
					}, {
						name: 'John Brown',
						age: 18,
						address: 'New York No. 1 Lake Park',
						date: '2016-10-03'
					},
					{
						name: 'Jim Green',
						age: 24,
						address: 'London No. 1 Lake Park',
						date: '2016-10-01'
					},
					{
						name: 'Joe Black',
						age: 30,
						address: 'Sydney No. 1 Lake Park',
						date: '2016-10-02'
					},
					{
						name: 'Jon Snow',
						age: 26,
						address: 'Ottawa No. 2 Lake Park',
						date: '2016-10-04'
					}
				]
			}
		},
		created() {
			getDictData("language_sort").then(res => {
				this.data = res.data.data;
				
			})
		},
		methods: {

		}
	}
</script>

<style scoped lang="less">
	.language-title {
		color: #333;
		font-size: 18px;
		font-weight: bolder;
	}

	.subtitle {
		color: #666;
		font-size: 12px;
		margin-top: 4px;
		margin-bottom: 3px;
	}
</style>