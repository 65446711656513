<template>
	<div ref="chartDom" style="width: 650px; height: 400px;"></div>
</template>

<script setup>
	import {
		ref,
		onMounted,
		onUnmounted,
		setup,
		nextTick
	} from 'vue';
	import * as echarts from 'echarts';

	import {
		getDictData
	} from '@/api/dictData'

	// 创建一个响应式引用来保存DOM元素
	const chartDom = ref(null);
	let chartInstance = null;
	let keyArr = [];
	let valueArr = [];

		// 初始化ECharts实例并设置配置项（这里以折线图为例，但可灵活替换）
		onMounted(async () => {


			await nextTick(); // 确保DOM已经渲染完成
			getDictData("sql_sort").then(res => {

				if (res.data.code == 200 && res.data.data.length > 0) {

					res.data.data.forEach(d => {
						// console.log(d.title,d.content)
						keyArr.push(d.title);
						valueArr.push(d.content)
					})

					initEcharts();
				}
			})

		});

	const initEcharts = () => {
		chartInstance = echarts.init(chartDom.value);
		const option = {
			// 这里是ECharts的配置项，可以根据需要绘制不同类型的图表
			title: {
				text: new Date().getFullYear()+'年前十数据库排行',
				subtext: "来源:db-engines"
				// textAlign:'right'
			},
			color: '#fcda90',
			tooltip: {},
			xAxis: {
				type: 'category',
				data: keyArr,
				axisLabel: {
					interval: 0,
					rotate: 20
				}
			},
			yAxis: {
				type: 'value'
			},
			series: [{
				data: valueArr,
				type: 'bar'
			}]
		};
		chartInstance.setOption(option);
	}



	// 销毁ECharts实例
	onUnmounted(() => {
		if (chartInstance != null && chartInstance.dispose) {
			chartInstance.dispose();
		}
	});
</script>

<style scoped>
	/* 添加一些CSS样式来美化图表容器（可选） */
</style>