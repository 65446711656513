<template>
	<div>
		<!-- <div class="fgx-layout"></div> -->
		<div class="footer-layout">
			<p><a href="https://beian.miit.gov.cn" target="_blank">滇ICP备18005519号</a></p>
		</div>
	</div>
</template>

<script>
</script>

<style scoped lang="less">
	.fgx-layout{
		width: 100%;
		height: 1px;
		background-color: #eee;
	}
	.footer-layout{
		width: 100%;
		height: 30px;
		line-height: 30px;
		margin: 0px auto;
		text-align: center;
		font-weight: bolder;
		background-color: #eee;
	}
	a{
		color: #515A6E;
	}
</style>