<template>
	<div class="menu-layout">
		<ul>
			<li @click="toHome()" key="0">首页</li>
			<li v-for="item in menuList" @click="toTarget(item.id)" :key="item.id">{{item.title}}</li>
			<li><a href="http://isoflow.maquge.com" target="_blank">网格绘图</a><Icon type="ios-shuffle" /></li>
		</ul>
	</div>
</template>


<script>
	import {
		listCourseType
	} from "@/api/courseType"
	export default {
		name: "Menu",
		data() {
			return {
				menuList: [{
						id: 1,
						title: "首页"
					},
					{
						id: 2,
						title: "数据库"
					},
					{
						id: 3,
						title: "后端"
					},
					{
						id: 4,
						title: "服务器"
					}, {
						id: 5,
						title: "中间件"
					}
				]
			};
		},
		created() {
			listCourseType().then(res => {
				this.menuList = res.data.data;
			})
		},
		methods: {
			toHome(){
				const {
					href
				} = this.$router.resolve({
					path: '/'
				})
				window.open(href, "_blank")
			},
			toTarget(id) {
				const {
					href
				} = this.$router.resolve({
					path: '/course',
					query: {
						id: id
					}
				})
				window.open(href, "_blank")
			}
		}
	};
</script>


<style scoped lang="less">
	@menuHeight: 60px;


	.menu-layout {
		float: left;
		width: 800px;
		height: @menuHeight;

		ul {
			li {
				float: left;
				width: 100px;
				height: @menuHeight;
				color: #333;
				text-align: center;
				line-height: @menuHeight;
				cursor: pointer;
				font-size: 16px;
			}
		}
	}
	/deep/ a{
		color: #333;
	}
	/deep/ a:hover{
		color: #333;
	}
</style>