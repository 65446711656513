import request from '@/utils/request'

// 查询课程
export function listCourse(id) {
  return request({
    url: 'common/course/list/'+id,
    method: 'get'
  })
}

//课程章节
export function courseDirectory(id) {
  return request({
    url: 'common/courseChapter/directory/'+id,
    method: 'get'
  })
}

export function courseChapterDetail(id) {
  return request({
    url: 'common/courseChapter/'+id,
    method: 'get',
	headers: {
	  isToken: true
	},
  })
}

export function getMyCourse() {
  return request({
    url: 'myCourse/list',
    method: 'get',
	headers: {
	  isToken: true
	},
  })
}